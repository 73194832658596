<template>
  <li :key="keyVal">
    <div class="cart-prd-info-wrapper">
        <div class="cart-left-content">
            <h3 class="cart-prd-name">
                {{product.title}}
            </h3>
            <ul class="snack-adjustments">
              <li v-for="adjustment in adjustments" :key="adjustment.id"> {{ adjustment.title }}</li>
            </ul>
        </div>
        <div class="cart-right-content">
            <p class="cart-prd-price">{{ total | tenzerCurrency }}</p>
            <div class="prd-count-container">
                <button class="prd-count-btn button-element" @click="updateQuantity( -1 )"><i class="fas fa-minus"></i></button>
                <input class="prd-count-number" :placeholder="product.quantity > 0 ? product.quantity:''" :value="product.quantity" :disabled="true">
                <button class="prd-count-btn active ml-1 button-element" @click="updateQuantity( 1 )"><i class="fas fa-plus"></i></button>
            </div>
        </div>
    </div>
  </li>
</template>

<script>
  import { mapActions } from 'vuex'
  import parsePrice from '../../utilities/parse-price'
  // const ProductQuantityPicker = () => import('@/components/product/ProductQuantityPicker')

  export default {
    name: 'BasketItem',

    components: {
      // ProductQuantityPicker
    },

    props: {
      product: {
        type: Object,
        default: () => {},
        required: true
      },
      keyVal: {
        type: null
      }
    },

    computed: {
      adjustments () {
        if (!this.product.adjustment) {
          return ''
        }

        return this.product.adjustment.filter(adjustment => adjustment.selected)
      },

      basePrice() {
        return parsePrice(this.product.price) + this.product.adjustment.reduce((total, option) => {
            return total + (option.selected ? option.price : 0)
        }, 0)
      },

      total () {
        let price = this.basePrice;

        if ( this.product.quantity && this.product.quantity > 0 ){
          price = this.product.quantity * price ;
        }

        return Number(price).toFixed(2);
      }
    },

    methods: {
      ...mapActions([ 'addItemToCart', 'removeItemFromCart' ]),

      updateQuantity (q) {
        let productData = Object.assign({}, this.product)
        productData.quantity += q;
        
        if( productData.quantity > 0 ){

          this.addItemToCart(productData)
        }else{
          this.remove();
        }
        this.$nextTick( () => {

          this.$forceUpdate();
        })
      },

      remove () {
        this.removeItemFromCart(this.product)
        this.$emit('remove')
      },
      convertPrice ( price ) {
        price = price.replace(',', '.');
        return price;
      },
      getProductTotal (){
        
        let price = this.product.price.replace( '€', '').trim();

        if( this.product.quantity && this.product.quantity > 0 ){
          price = ( this.product.quantity * this.convertPrice(price) );
          price = parseFloat( price ) .toFixed(2);
        }
        return price;
      }
    },
  }
</script>
